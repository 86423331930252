import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import CustomImage from "../components/UI/CustomImage"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query IndexQuery {
        contentJson(fileID: {eq: "infos"}) {
          fileID
          logoBig {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
          heroText
          heroIMG {
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 60)
            }
          }
          slideshow {
            slides {
              text
              image {
                publicURL
                childImageSharp {
                  gatsbyImageData(quality: 60)
                }
              }
              btnLabel
              btnHref
            }
          }
        }
      }
    `
  )
  const content = data.contentJson
  return(
    <Layout>
      <Seo title="Bienvenue sur menu factory" />
      <div className="p-4 pt-0 flex-grow flex">

      <Swiper className="mySwiper flex-grow flex" loop={content.slideshow.slides.length > 1}>
        {content.slideshow.slides.map((slide, i) => (
          <SwiperSlide key={i} className="flex">
            <div className="relative flex-grow grid place-content-center">
              {/* <CustomImage
                img={slide.image}
                layout="fullWidth"
                alt="Hero header background"
                className="inset-0 h-full z-10"
                style={{
                  position: 'absolute'
                }}
              /> */}
              <div className="relative z-30 container text-center max-w-4xl sm:px-8">
                <CustomImage img={content.logoBig} alt="Logo" width="450px" className="mx-auto max-w-[80%] mb-6" />
                {slide.text && (
                  <h1 className="text4xl sm:text-5xl lg:text-6xl pb-8 leading-tight sm:leading-tight lg:leading-tight">{content.heroText}</h1>
                )}
                {slide.btnHref && (
                  <div className="flex justify-center mt-4 md:mt-6">
                    <Link to={slide.btnHref} className="btn btn-white">{slide.btnLabel}</Link>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>        

      </div>
    </Layout>
  )
}

export default IndexPage
